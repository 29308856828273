import { SwiperOptions } from 'swiper/types';

export interface CategoryItemType {
  id: string;
  title?: string;
  description?: string;
  link: string;
  image: string;
}
export type ItemCategoryType = {
  link: string;
  title: string;
  mobile_image: string;
  name: string;
  label: string;
  web_image: string;
  web_image_width?: number;
  web_image_height?: number;
  mobile_image_width?: number;
  mobile_image_height?: number;
  description: string;
  link_type: string;
  logged_in_users_only: boolean;
  calculated_relative_url?: string;
};

export type CategoryColorType = {
  description_background_color?: string;
  label_text_color?: string;
  background_color?: string;
  cta_color: string;
  title_color: string;
  description_color?: string;
  border_color?: string;
  desktop_per_view?: number;
  tablet_per_view?: number;
  mobile_per_view?: number;
  slider_fade_effect?: boolean;
  mobile_only?: boolean;
  desktop_mobile_show?: boolean;
  mobile_only_margin_top?: number;
  color_theme?: {
    label_text_color: string;
    background_color?: string;
  };
};

export type CategoryContentType = {
  additional_margin_top: number;
  color_theme: CategoryColorType;
  image_style: CategoriesStyle;
  items: ItemCategoryType[];
  section_title?: string;
  label?: string;
  link?: string;
  calculated_relative_url?: string;
  items_per_row?: number;
  link_type?: string;
  background_image?: string;
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
  title_class?: string;
  style?: string;
  component_type?: string;
  id?: string;
};

export interface CategoryBackgroundType {
  color?: string;
  image?: string;
}

export interface CategoriesType {
  title?: string;
  titleColor?: string;
  ctaColor?: string;
  style?: CategoriesStyle;
  ctaLink?: string;
  ctaText?: string;
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
  background?: CategoryBackgroundType;
  items: CategoryItemType[];
  disablePadding?: boolean;
}

export const enum CategoriesStyle {
  /* eslint-disable no-unused-vars */
  Circle = 'Single_Line_Small_Circle', // style 1
  Square = 'Single_Line_Small_Square', // style 2
  Rectangle = 'Single_Line_Small_Rectangle', // style 3
  RectangleWithTextBackground = 'Single_Line_Medium_Square', // style 4
  CircleMultiLine = 'Double_Line_Small_Circle', // style 5
  SquareMultiLine = 'Double_Line_Small_Square',
  DoubleLineSmallCircle = 'Double_Line_Small_Circle',
  RectangleMultiLine = 'Double_Line_Small_Rectangle', // style 7
  SpecialMultiline = 'Big_Square_With_Double_Line_Small_Square', // style 8
  SpecialMultilineTall = 'Big_Rectangle_With_Double_Line_Small_Rectangle', // style 9
  Tall = 'Large_Rectangle', // style 10
  Wide = 'Single_Line_Medium_Width_More_Rectangle', // style 11
  Style12 = 'Single_Line_Small_Width_More_Rectangle', // style 12
  Style13 = 'Single_Line_Small_Width_More_Rectangle_With_Description', // style 13
  /* eslint-enable no-unused-vars */
}

import clsx from 'clsx';
import { Underline } from 'components/buttons/underline';
import Image from 'next/image';
import { HomeBannersLayoutProps } from 'types/prop-types';

export default function HomeBannersLayout({
  children,
  title,
  titleCentered,
  showAllLink,
  showAll,
  titleClass = '',
  titleColor = '',
  showAllClass = '',
  backgroundColor = '',
  backgroundImage,
  showAllLinkColor = '',
  customContainerWidth,
}: HomeBannersLayoutProps) {
  return (
    <>
      <style>
        {'.homeBannersContainer:has(.swiper-wrapper:empty) {display: none}'}
      </style>

      <div
        className={clsx(
          'homeBannersContainer',
          (backgroundColor || backgroundImage) &&
            'relative py-[52px] max-lg:py-8'
        )}
        style={{ backgroundColor: backgroundColor }}
      >
        {backgroundImage && (
          <Image src={backgroundImage} alt='' fill sizes='100%' />
        )}
        <div>
          {(title || showAllLink) && (
            <div
              className={clsx(
                'relative mb-4 flex justify-between',
                `container-base ${customContainerWidth}`
              )}
            >
              {titleCentered && <div className='justify-start'></div>}
              <h2
                className={clsx(
                  titleCentered ? 'justify-center' : 'justify-start',
                  'flex',
                  'truncate text-2xl max-sm:text-xl',
                  titleClass,
                  showAllLink &&
                    'max-w-[280px] sm:max-w-[650px] lg:max-w-[800px] xl:max-w-[1100px] ltr:pr-2 sm:ltr:pr-0 rtl:pl-2 sm:rtl:pl-0'
                )}
                style={{ color: titleColor }}
              >
                {title && title.substring(0, 30)}
              </h2>
              {showAllLink && (
                <div className='flex items-center justify-end whitespace-nowrap'>
                  <Underline
                    href={showAllLink || ''}
                    className={showAllClass}
                    color={showAllLinkColor}
                  >
                    {showAll?.substring(0, 19)}
                  </Underline>
                </div>
              )}
            </div>
          )}
          <div
            className={clsx(
              `container-base px-0 max-lg:ltr:pl-4 max-lg:rtl:pr-4 ${customContainerWidth}`
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

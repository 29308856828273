'use client';
import { useState, useMemo, useEffect, useRef } from 'react';
import ContentfulLink from 'components/contentful-link';
import { PlayIcon } from 'components/icon';
import { PauseIcon } from '@heroicons/react/24/outline';
import BaseSwiper from 'components/swiper/base-swiper';
import { HomeHeroBannerType, HeroBannerItemType } from 'types/cms/hero-banner';
import ReactPlayer from 'react-player';
import clsx from 'clsx';
import { IVideoSettings } from 'types/cms/gss';
import { gtmSetViewPromotion } from 'lib/gtm';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { Media } from 'components/media';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';

function titleHandler(
  data: HomeHeroBannerType,
  title: string,
  isMobile: boolean
) {
  switch (
    data.image_size_definition ||
    data.image_size_definition_padding ||
    data.image_size_definition_padding_horizontal
  ) {
    case 'LARGE':
      return title?.slice(0, 40);
    case 'MEDIUM':
      if (isMobile) {
        return title?.slice(0, 40);
      } else {
        return title?.slice(0, 34);
      }
    case 'SMALL':
      return title?.slice(0, 35);
    case 'XSMALL':
      return title?.slice(0, 24);
    default:
      return title?.slice(0, 40);
  }
}

export default function HeroBanner(props: {
  data: HomeHeroBannerType;
  bannerType: string;
  disableFadeEffect?: boolean;
  contentPadding?: string;
  videoSettings?: IVideoSettings;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { data, videoSettings, language, region } = props;
  const [isMobile, setIsMobile] = useState(true);
  const [isSlideChanged, setIsSlideChanged] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 1024);
    }
  }, []);

  const sliderHeightControl = useMemo(() => {
    return (data: HomeHeroBannerType) => {
      switch (
        data.image_size_definition ||
        data.image_size_definition_padding ||
        data.image_size_definition_padding_horizontal
      ) {
        case 'XLARGE':
          return 'h-[500px]';
        case 'LARGE':
          return `${
            data.image_size_definition ? 'h-[366px]' : 'h-[250px]'
          } sm:h-[500px]`;
        case 'MEDIUM':
          return 'h-[200px] sm:h-[340px]';
        case 'SMALL':
          return 'h-[150px] sm:h-[200px]';
        default:
          return 'h-[366px] sm:h-[500px]';
      }
    };
  }, []);

  function buttonHandler(item: HeroBannerItemType) {
    switch (
      data.image_size_definition ||
      data.image_size_definition_padding ||
      data.image_size_definition_padding_horizontal
    ) {
      case 'XLARGE':
      case 'LARGE':
        return (
          item?.buttonName && (
            <button
              className='mt-4 h-10 w-auto whitespace-nowrap rounded-lg bg-blue px-6 py-3 text-custom-sm font-semibold text-white hover:bg-blue-hover lg:h-[50px] lg:w-[213px] lg:py-4 lg:text-custom-base'
              style={{
                backgroundColor:
                  item.buttonBackgroundColor && item.buttonBackgroundColor,
                color: item.buttonTextColor && item.buttonTextColor,
              }}
            >
              {item.buttonName.slice(0, 19)}
            </button>
          )
        );
      case 'MEDIUM':
        return (
          item?.buttonName && (
            <button
              className='mt-4 h-10 w-auto whitespace-nowrap rounded-lg bg-blue px-6 py-3 text-custom-xs font-semibold text-white hover:bg-blue-hover lg:h-[50px] lg:w-[213px] lg:py-4 lg:text-custom-base'
              style={{
                backgroundColor:
                  item.buttonBackgroundColor && item.buttonBackgroundColor,
                color: item.buttonTextColor && item.buttonTextColor,
              }}
            >
              {item.buttonName.slice(0, 19)}
            </button>
          )
        );
      case 'SMALL':
        return (
          item?.buttonName && (
            <button
              className='mt-4 h-10 w-auto whitespace-nowrap rounded-lg bg-blue px-6 py-3 text-custom-sm font-semibold text-white hover:bg-blue-hover lg:h-[50px] lg:w-[213px] lg:py-4 lg:text-custom-base'
              style={{
                backgroundColor:
                  item.buttonBackgroundColor && item.buttonBackgroundColor,
                color: item.buttonTextColor && item.buttonTextColor,
              }}
            >
              {item.buttonName.slice(0, 19)}
            </button>
          )
        );
    }
  }

  const sliderItemHtml = (item: HeroBannerItemType, index: number) => {
    return (
      <>
        <Media
          srcset={{
            mobileImage: item?.mobileImage,
            desktopImage: item?.desktopImage,
            desktopImageWidth: item?.desktopImageWidth,
            desktopImageHeight: item?.desktopImageHeight,
            mobileImageWidth: item?.mobileImageWidth,
            mobileImageHeight: item?.mobileImageHeight,
          }}
          alt={item.subTitle || 'NAHDI'}
          className={`swiper-lazy h-full w-full object-cover ${
            data.image_size_definition ? '' : 'rounded-lg'
          }`}
          fetchPriority={index === 0 ? 'high' : 'low'}
        />
        <div className='swiper-lazy-preloader'></div>
        <div
          className={`${
            data.image_size_definition
              ? !item.horizontal_alignment ||
                item.horizontal_alignment === 'LEFT' ||
                item.horizontal_alignment === 'CENTER'
                ? 'left-0 right-0'
                : 'end-0'
              : 'sm:ps-6'
          } ${
            item.horizontal_alignment === 'CENTER' && 'justify-center'
          } container-base absolute bottom-0 top-0 flex items-center ${
            props.contentPadding ? props.contentPadding : ''
          }`}
        >
          <div className='h-full w-1/2 justify-center py-4 sm:w-[320px] lg:flex lg:flex-col xl:w-[540px]'>
            {item.subTitle && (
              <div
                className={`line-clamp-2 flex h-1/2 w-full items-end ${
                  data.image_size_definition === 'small'
                    ? 'h-12 text-custom-lg sm:text-2xl'
                    : 'text-xl sm:text-[34px]'
                }`}
                style={{ color: item?.labelTextColor }}
              >
                <h2>{titleHandler(data, item.subTitle, isMobile)}</h2>
              </div>
            )}
            <div
              className={clsx(
                'h-1/2 w-full',
                !item.subTitle ? 'flex !h-4/6 items-end' : ''
              )}
            >
              {buttonHandler(item)}
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!data?.items?.length) return null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    gtmSetViewPromotion(data, 'Hero Banner');
  }, [data]);
  return (
    <div
      className={`${
        data.image_size_definition_padding_horizontal ||
        data.image_size_definition_padding
          ? 'mx-auto py-10 sm:max-w-[1312px]'
          : ''
      } relative`}
      // TO DO: change this to dynamic parameters
      // -32px comes from swiper adding 28 to this fix small margin gap for hero banner
      style={{ marginTop: '-50px', marginBottom: '-50px' }}
    >
      {/* TO DO change gradient class/value to dynamic */}
      <BaseSwiper
        loadingSkeleton={
          <BannerLoadingSkeleton
            className={`${sliderHeightControl(props.data)}`}
          />
        }
        className={`relative overflow-hidden ${
          data.image_size_definition_padding_horizontal
            ? ''
            : 'hero-banner-padding'
        } ${
          data.image_size_definition_padding
            ? 'hero-banner-with-padding'
            : 'hero-banner'
        } ${props.disableFadeEffect ? '' : 'fade-hero-banner'} ${
          data.image_size_definition_padding_horizontal ? 'container-base' : ''
        } hero-banner-${data.image_size_definition?.toLowerCase()}`}
        swiperProps={{
          ...(data.autoplay &&
            data.speed && {
              autoplay: {
                delay: data.speed * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              loop: data.items.every((v) => v.asset_type == 'IMAGE'),
            }),
          pagination: true,
          slidesPerView: data.image_size_definition_padding_horizontal
            ? 1.1
            : 1,
          breakpoints: {
            320: {
              spaceBetween: data.image_size_definition_padding_horizontal
                ? -12
                : 1,
            },
            720: {
              spaceBetween: 12,
            },
          },
          loop: true,
          navigation: data.image_size_definition_padding_horizontal
            ? {
                nextEl: `.${props.bannerType}-custom-right`,
                prevEl: `.${props.bannerType}-custom-left`,
                disabledClass: 'swiper-button-disabled',
              }
            : true,
          onSlideChangeTransitionStart: () => setIsSlideChanged(true),
          onSlideChangeTransitionEnd: () => setIsSlideChanged(false),
        }}
      >
        {data.items?.map((item: any, index: number) => {
          return item.asset_type !== 'IMAGE' ? (
            <div
              key={`video-banner-${index}`}
              className={`${sliderHeightControl(props.data)} relative w-full ${
                data.image_size_definition ? '' : 'container-base mx-auto'
              }`}
            >
              <VideoWidget
                item={item}
                key={index}
                data={data}
                isSlideChanged={isSlideChanged}
                videoSettings={videoSettings}
              />
            </div>
          ) : (
            <div
              key={`hero-banner-${index}`}
              className={`${sliderHeightControl(props.data)} w-full ${
                data.image_size_definition ? '' : 'container-base mx-auto'
              }`}
            >
              {item.link ? (
                <ContentfulLink item={item} language={language} region={region}>
                  {sliderItemHtml(item, index)}
                </ContentfulLink>
              ) : (
                sliderItemHtml(item, index)
              )}
            </div>
          );
        })}
      </BaseSwiper>

      {data.image_size_definition_padding_horizontal && (
        <>
          <button
            className={`${props.bannerType}-custom-left swiper-button-prev`}
          ></button>
          <button
            className={`${props.bannerType}-custom-right swiper-button-next`}
          ></button>
        </>
      )}
    </div>
  );
}

export function VideoWidget({
  data,
  item,
  isMobile = false,
  isSlideChanged = false,
  videoSettings,
}: {
  data: HomeHeroBannerType;
  item: HeroBannerItemType;
  isMobile?: boolean;
  isSlideChanged?: boolean;
  videoSettings?: IVideoSettings;
}) {
  const isAutoPlay = videoSettings?.video_autoplay === 'true';
  const [isPlaying, setIsPlaying] = useState(isAutoPlay);
  const videoRef = useRef<HTMLVideoElement>(null);
  const getSize = () => {
    switch (
      data.image_size_definition ||
      data.image_size_definition_padding ||
      data.image_size_definition_padding_horizontal
    ) {
      case 'LARGE':
        return 'LARGE';
      case 'MEDIUM':
        return 'MEDIUM';
      case 'SMALL':
        return 'SMALL';
      case 'XSMALL':
        return 'XSMALL';
      default:
        return 'LARGE';
    }
  };

  useEffect(() => {
    if (isSlideChanged) {
      setIsPlaying(false);
      videoRef?.current?.pause();
    }
  }, [isSlideChanged]);
  return (
    <>
      <div className='relative h-full w-full'>
        {item.asset_type == 'VIDEO' ? (
          <video
            className={clsx([
              !data.image_size_definition && 'rounded-lg',
              'h-full w-full object-cover',
            ])}
            loop
            ref={videoRef}
            src={item.video_url}
            poster={item.desktopImage}
            playsInline
            {...(isAutoPlay
              ? {
                  muted: true,
                  autoPlay: true,
                }
              : {})}
          />
        ) : (
          <div
            className={clsx([
              !data.image_size_definition && 'rounded-lg',
              'h-full w-full bg-black lg:relative',
            ])}
          >
            <ReactPlayer
              className={clsx([
                !data.image_size_definition && 'rounded-lg',
                'lg:absolute lg:bottom-0 lg:left-0 lg:right-0 lg:top-0',
              ])}
              url={item.video_url}
              playing={isPlaying}
              loop={true}
              width='100%'
              height='100%'
              volume={0.5}
              playsinline
              {...(isAutoPlay
                ? {
                    muted: true,
                  }
                : {})}
            />
          </div>
        )}

        <div className='absolute left-0 top-0 h-full w-full bg-black/10'></div>
      </div>

      <div
        className={`h-full w-full bg-light-gray-300 ${
          data.image_size_definition ? '' : 'rounded-lg px-4'
        }`}
      ></div>
      {/* TO DO: manage multiple video banners */}
      <div className='absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          {!isAutoPlay && (
            <>
              {isPlaying ? (
                <div className='flex h-[82px] w-[82px] items-center justify-center'>
                  <div className='-mt-[6px] flex h-[39px] w-[39px] cursor-pointer items-center justify-center rounded-full border border-primary-blue bg-white'>
                    <PauseIcon
                      color='#2C6BA3'
                      onClick={() => {
                        setIsPlaying(false);
                        videoRef?.current?.pause();
                      }}
                      className='h-[24px] w-[24px]'
                    />
                  </div>
                </div>
              ) : (
                <PlayIcon
                  onClick={() => {
                    setIsPlaying(true);
                    videoRef?.current?.play();
                  }}
                  className='h-[82px] w-[82px] cursor-pointer'
                />
              )}
            </>
          )}
          {item.subTitle && (
            <h2
              className={clsx(
                getSize() === 'LARGE' && 'bottom-[70px] ltr:left-3 rtl:right-3',
                getSize() === 'SMALL' && 'bottom-[40px] ltr:left-4 rtl:right-4',
                getSize() === 'MEDIUM' &&
                  'bottom-[35px] ltr:left-4 rtl:right-4',
                getSize() === 'XSMALL' && 'bottom-[5px] ltr:left-4 rtl:right-4',
                'absolute sm:bottom-8',
                'w-full text-custom-lg sm:relative lg:max-w-[550px] lg:text-center lg:text-[32px]',
                'sm:ltr:left-0 sm:rtl:right-0'
              )}
              style={{ color: item?.labelTextColor }}
            >
              {titleHandler(data, item.subTitle, isMobile)}
            </h2>
          )}
        </div>
      </div>
    </>
  );
}

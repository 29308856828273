'use client';

import { Link } from 'components/link';
import BaseSwiper from 'components/swiper/base-swiper';
import { HomeHeroBannerType } from 'types/cms/hero-banner';
import { VideoWidget } from '.';
import { ChevronRightIcon } from 'components/icon';
import { useState } from 'react';
import { Media } from 'components/media';

export default function HeroBannerXSmall(props: {
  data: HomeHeroBannerType;
  bannerType: string;
}) {
  const { data } = props;
  const [isSlideChanged, setIsSlideChanged] = useState(false);

  const sliderItemHtml = (item: any) => {
    return (
      <>
        <Media
          srcset={{
            mobileImage: item?.mobileImage,
            desktopImage: item?.desktopImage,
            desktopImageWidth: item?.desktopImageWidth,
            desktopImageHeight: item?.desktopImageHeight,
            mobileImageWidth: item?.mobileImageWidth,
            mobileImageHeight: item?.mobileImageHeight,
          }}
          alt={item.subTitle || 'NAHDI'}
          className={`swiper-lazy h-full w-full object-cover ${
            data.image_size_definition ? '' : 'rounded-lg'
          }`}
          fetchPriority='auto'
        />
        <div className='swiper-lazy-preloader'></div>

        <div className='absolute bottom-0 top-0 flex h-[50px] w-full items-center justify-center gap-4 md:h-[100px] md:gap-6'>
          {item.subTitle && (
            <div className='text-custom-base md:text-[24px]'>
              <h2 className='font-light'>
                {item.subTitle.length > 24
                  ? item.subTitle.slice(0, 24)
                  : item.subTitle}
              </h2>
            </div>
          )}
          {item.url && (
            <div className='relative flex w-[auto] items-center rounded-[60px] bg-yellow rtl:space-x-reverse'>
              <span className='pb-1.5 pt-[5px] text-custom-base font-bold lg:py-[11px] lg:text-2xl lg:font-semibold ltr:pl-3 ltr:pr-6 ltr:lg:pl-6 ltr:lg:pr-[46px] rtl:pl-6 rtl:pr-1.5 rtl:lg:pl-[46px] rtl:lg:pr-6'>
                {item.buttonName.slice(0, 10)}
              </span>
              <ChevronRightIcon className='sm:absolute sm:h-8 sm:w-8 ltr:right-1 rtl:left-1' />
            </div>
          )}
        </div>
      </>
    );
  };
  if (!data.items.length) return null;

  return (
    <div
      className={`${
        data?.image_size_definition_padding_horizontal ||
        data?.image_size_definition_padding
          ? '-pb-10 mx-auto px-2 sm:max-w-[1312px] sm:px-0'
          : '-my-8 md:my-0'
      } relative`}
    >
      <BaseSwiper
        className={`hero-banner hero-banner-xsmall relative h-[70px] overflow-hidden sm:h-[120px] ${
          data?.image_size_definition_padding_horizontal ? 'container-base' : ''
        }`}
        swiperProps={{
          // autoplay: { delay: 8000 },
          pagination: true,
          slidesPerView: data.image_size_definition_padding_horizontal
            ? 1.1
            : 1,
          navigation:
            data.image_size_definition_padding_horizontal ||
            data.image_size_definition_padding
              ? {
                  nextEl: `.${props.bannerType}-custom-small-right`,
                  prevEl: `.${props.bannerType}-custom-small-left`,
                  disabledClass: 'swiper-button-disabled',
                }
              : true,
          autoplay: data?.autoplay && {
            delay: (data?.speed ?? 0) * 1000,
            disableOnInteraction: false,
          },
          spaceBetween: 12,
          breakpoints: {},
          onSlideChangeTransitionStart: () => setIsSlideChanged(true),
          onSlideChangeTransitionEnd: () => setIsSlideChanged(false),
        }}
      >
        {data.items.map((item, index) =>
          item.asset_type === 'VIDEO' ? (
            <div
              key={`video-banner-${index}`}
              className={`relative h-[50px] w-full sm:h-[100px] ${
                data.image_size_definition ? '' : 'container-base mx-auto'
              }`}
            >
              <VideoWidget
                item={item}
                data={data}
                isSlideChanged={isSlideChanged}
              />
            </div>
          ) : (
            <div
              key={`hero-banner-${index}`}
              className={`relative h-[50px] w-full px-0 sm:h-[100px] ${
                data.image_size_definition ? '' : 'container-base mx-auto'
              }`}
            >
              {item.url && (
                <Link href={item.url || ''}>{sliderItemHtml(item)}</Link>
              )}
              {!item.url && sliderItemHtml(item)}
            </div>
          )
        )}
      </BaseSwiper>

      {(data?.image_size_definition_padding_horizontal ||
        data?.image_size_definition_padding) && (
        <>
          <button
            className={`${props.bannerType}-custom-small-left swiper-button-prev !top-[calc(50%-10px)]`}
          ></button>
          <button
            className={`${props.bannerType}-custom-small-right swiper-button-next !top-[calc(50%-10px)]`}
          ></button>
        </>
      )}
    </div>
  );
}

'use client';
import BaseSwiper, { CustomSwiperType } from 'components/swiper/base-swiper';
import {
  CategoriesStyle,
  CategoryContentType,
  ItemCategoryType,
} from 'types/cms/categories-banner';
import { twMerge } from 'tailwind-merge';
import HomeBannersLayout from 'components/layout/homepage/home-banners-layout';
import clsx from 'clsx';
import { useAppSelector } from 'redux/hooks';
import ContentfulLink from 'components/contentful-link';
import HydrationWrapper from 'components/hydration-wrapper';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { Media } from 'components/media';

export default function CategoriesCarousel({
  data,
  componentIndex,
  language,
  region,
}: {
  data?: CategoryContentType;
  componentIndex: number;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isLoggedIn = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );

  let customClassName = '';
  const currentStyle = data?.image_style || CategoriesStyle.Circle;

  const calculateStyle = () => {
    return {
      // !!! DON'T REMOVE THIS COMMENT. Since these are added dynamically, Tailwind will remove them thinking they are not used
      // Having them anywhere in the code, including inside of a comment, will prevent tailwind from removing them.
      //
      // aspect-[90/112] aspect-[196/320] aspect-[172/88] aspect-[100/70] aspect-[112/83]
      imageAspectRatio: (() => {
        switch (currentStyle) {
          case CategoriesStyle.Circle:
          case CategoriesStyle.CircleMultiLine:
          case CategoriesStyle.SpecialMultiline:
          case CategoriesStyle.Square:
          case CategoriesStyle.SquareMultiLine:
          case CategoriesStyle.RectangleWithTextBackground:
            return '1/1';
          case CategoriesStyle.Rectangle:
          case CategoriesStyle.RectangleMultiLine:
            return '136/169';
          case CategoriesStyle.Tall:
            return '196/320';
          case CategoriesStyle.SpecialMultilineTall:
            return '385/482';
          case CategoriesStyle.Wide:
            return '172/88';
          case CategoriesStyle.Style12:
            return '100/70';
          case CategoriesStyle.Style13:
            return '112/83';
          default:
            return '1/1';
        }
      })(),

      imageIsCircular: [
        CategoriesStyle.Circle,
        CategoriesStyle.CircleMultiLine,
        // CategoriesStyle.DoubleLineSmallCircle,
      ].includes(currentStyle),
      titleHasBackground: [
        CategoriesStyle.RectangleWithTextBackground,
        CategoriesStyle.Wide,
        CategoriesStyle.Style13,
      ].includes(currentStyle),
      titleNotCentered: [CategoriesStyle.Style12].includes(currentStyle),
      titleIsStyled: [CategoriesStyle.Style13].includes(currentStyle),
      includeDescription: [CategoriesStyle.Style13].includes(currentStyle),
      hasShadow: [
        CategoriesStyle.RectangleWithTextBackground,
        CategoriesStyle.Wide,
        CategoriesStyle.Style13,
      ].includes(currentStyle),
    };
  };
  const slidesHasLabel = data?.items?.some((item) => item.label);
  function calculateSlidesPerView(
    slidesPerViewMobile: number,
    spaceBetweenMobile: number,
    slidesPerViewTablet: number,
    spaceBetweenTablet: number,
    slidesPerViewDesktop: number,
    spaceBetweenDesktop: number
  ): {
    breakpoints: {
      [key: number]: { slidesPerView: number; spaceBetween: number };
    };
  } {
    return {
      breakpoints: {
        350: {
          slidesPerView: slidesPerViewMobile,
          spaceBetween: spaceBetweenMobile,
        },
        768: {
          slidesPerView: slidesPerViewTablet,
          spaceBetween: spaceBetweenTablet,
        },
        1024: {
          slidesPerView: slidesPerViewDesktop,
          spaceBetween: spaceBetweenDesktop,
        },
      },
    };
  }
  function SlidesPerViewHandler() {
    switch (currentStyle) {
      case CategoriesStyle.Circle:
        return calculateSlidesPerView(4.7, 12, 8.7, 12, 10, 22);
      case CategoriesStyle.Square:
        return calculateSlidesPerView(4.7, 12, 6.7, 12, 8, 16);
      case CategoriesStyle.Rectangle:
        return calculateSlidesPerView(3.7, 12, 6.7, 12, 8, 16);
      case CategoriesStyle.RectangleWithTextBackground:
        return calculateSlidesPerView(3.5, 12, 6.7, 12, 8, 16);
      case CategoriesStyle.DoubleLineSmallCircle:
        return calculateSlidesPerView(4.2, 8, 8.7, 12, 10, 22);
      case CategoriesStyle.SquareMultiLine:
        return calculateSlidesPerView(4.2, 8, 6.7, 12, 8, 16);
      case CategoriesStyle.RectangleMultiLine:
        return calculateSlidesPerView(3.7, 12, 6.7, 12, 8, 16);
      case CategoriesStyle.SpecialMultiline:
        if (slidesHasLabel) {
          return calculateSlidesPerView(4.1, 8, 6.2, 12, 7, 16);
        } else {
          return calculateSlidesPerView(4.3, 8, 4.8, 12, 6, 15);
        }
      case CategoriesStyle.SpecialMultilineTall:
        if (slidesHasLabel) {
          return calculateSlidesPerView(3.5, 8, 6.2, 12, 6.5, 16);
        } else {
          return calculateSlidesPerView(2.8, 8, 6.8, 12, 6, 16);
        }
      case CategoriesStyle.Tall:
        return calculateSlidesPerView(1.9, 8, 2.4, 12, 5, 16);
      case CategoriesStyle.Wide:
        return calculateSlidesPerView(2.1, 8, 2.6, 12, 3, 16);
      case CategoriesStyle.Style12:
        return calculateSlidesPerView(3.5, 8, 7.7, 12, 8, 16);
      case CategoriesStyle.Style13:
        return calculateSlidesPerView(3.2, 8, 5.2, 12, 7, 16);
      default:
        return calculateSlidesPerView(4.7, 12, 8.7, 12, 10, 22);
    }
  }

  function calculateSliderLength() {
    switch (currentStyle) {
      case CategoriesStyle.Circle:
        return 10;
      case CategoriesStyle.Square:
        return 8;
      case CategoriesStyle.Rectangle:
        return 8;
      case CategoriesStyle.RectangleWithTextBackground:
        return 8;
      case CategoriesStyle.DoubleLineSmallCircle:
        return 10;
      case CategoriesStyle.SquareMultiLine:
        return 8;
      case CategoriesStyle.RectangleMultiLine:
        return 8;
      case CategoriesStyle.SpecialMultiline:
        if (slidesHasLabel) {
          return 8;
        } else {
          return 6;
        }
      case CategoriesStyle.SpecialMultilineTall:
        if (slidesHasLabel) {
          return 6.5;
        } else {
          return 6;
        }
      case CategoriesStyle.Tall:
        return 5;
      case CategoriesStyle.Wide: {
        return 3;
      }
      case CategoriesStyle.Style12: {
        return 8;
      }
      case CategoriesStyle.Style13: {
        return 7;
      }
      default:
        return 10;
    }
  }
  const breakpoints = SlidesPerViewHandler();
  const calculateSwiperProps = () => {
    const temp: CustomSwiperType = {
      navigation: {
        nextEl: `.custom-${componentIndex}-category-right-button`,
        prevEl: `.custom-${componentIndex}-category-left-button`,
        disabledClass: 'swiper-button-disabled',
      },
      breakpoints: breakpoints.breakpoints,
      wrapperClass: 'has-overflowing-shadow',
    };
    customClassName = 'categories-carousel';
    if (
      [
        CategoriesStyle.CircleMultiLine,
        CategoriesStyle.RectangleMultiLine,
        // CategoriesStyle.DoubleLineSmallCircle,
      ].includes(currentStyle)
    ) {
      temp.grid = {
        rows: 2,
        fill: 'row',
      };
    } else if ([CategoriesStyle.SquareMultiLine].includes(currentStyle)) {
      temp.grid = {
        rows: 2,
        fill: 'row',
      };
    } else if (CategoriesStyle.SpecialMultiline.includes(currentStyle)) {
      customClassName = customClassName + ' special-multiline';
      temp.grid = {
        rows: 2,
        fill: 'column',
      };
    } else if (CategoriesStyle.SpecialMultilineTall.includes(currentStyle)) {
      customClassName =
        customClassName + ' special-multiline special-multiline-tall';
      temp.grid = {
        rows: 2,
        fill: 'column',
      };
    } else {
      delete temp.grid;
    }
    if (
      [CategoriesStyle.Circle, CategoriesStyle.CircleMultiLine].includes(
        currentStyle
      )
    ) {
      temp.fadelast = data?.color_theme?.slider_fade_effect ? 'show' : 'hide';
    }
    return temp;
  };
  const style = calculateStyle();
  const calculateLabel = (categoryItem: ItemCategoryType, index: number) => {
    switch (currentStyle) {
      case CategoriesStyle.Circle: {
        return 'font-semibold text-custom-xs lg:text-xs mt-2 text-center text-gray-dark';
      }
      case CategoriesStyle.Square: {
        return 'font-semibold text-custom-xs lg:text-custom-sm lg:font-normal lg:mt-3 mt-2 text-center text-gray-dark';
      }
      case CategoriesStyle.Rectangle: {
        return 'font-semibold text-custom-xs lg:text-xs lg:mt-3 mt-2 lg:mt-3 mt-2 text-center text-gray-dark';
      }
      case CategoriesStyle.RectangleWithTextBackground: {
        if (categoryItem.description) {
          return 'font-semibold text-blue text-custom-sm lg:mt-3 mt-2 text-center';
        } else {
          return 'font-semibold text-custom-xs lg:text-custom-sm lg:font-normal lg:mt-3 mt-2 text-center text-gray-dark';
        }
      }
      case CategoriesStyle.CircleMultiLine: {
        return 'font-normal text-xs lg:font-semibold mt-2 text-center text-gray-dark';
      }
      case CategoriesStyle.SquareMultiLine: {
        return 'font-normal text-xs lg:text-custom-sm lg:mt-3 mt-2 text-center text-gray-dark';
      }
      case CategoriesStyle.RectangleMultiLine: {
        return 'font-normal text-xs lg:text-custom-sm lg:mt-3 mt-[7px] text-center text-gray-dark';
      }
      case CategoriesStyle.SpecialMultiline: {
        if (index == 0) {
          return 'font-normal text-custom-lg lg:text-xl absolute text-center text-gray-dark';
        } else {
          return 'font-normal text-xs lg:text-custom-sm lg:mt-3 mt-2 text-center text-gray-dark';
        }
      }
      case CategoriesStyle.SpecialMultilineTall: {
        if (index == 0) {
          return 'font-normal text-custom-lg lg:text-xl absolute text-center text-gray-dark';
        } else {
          return 'font-normal text-xs lg:text-custom-sm lg:mt-3 mt-2 text-center text-gray-dark !leading-[19px]';
        }
      }
      case CategoriesStyle.Tall: {
        return 'font-normal text-custom-base text-center text-gray-dark absolute top-4 left-4 rigth-4 ltr:text-left rtl:text-right';
      }
      case CategoriesStyle.Wide: {
        return 'font-semibold w-full text-start text-custom-sm lg:text-custom-lg lg:font-normal p-3 lg:p-4 text-center text-gray-dark';
      }
      case CategoriesStyle.Style12: {
        return 'font-normal w-full text-start text-xs lg:text-custom-sm lg:mt-3 mt-2 text-gray-dark';
      }
      case CategoriesStyle.Style13: {
        return 'font-semibold text-blue text-custom-sm lg:mt-3 mt-2 text-center';
      }
      default:
        return 'font-semibold text-custom-xs lg:text-xs text-center  text-gray-dark';
    }
  };
  const swiperProps = calculateSwiperProps();
  function calculateImageSize(index: number, hasLabel: boolean) {
    switch (currentStyle) {
      case CategoriesStyle.Circle:
        return 'max-h-[70px] lg:max-h-[100px] max-w-[70px] lg:max-w-[100px]';
      case CategoriesStyle.Square:
        return 'max-h-[70px]  lg:max-h-[136px] max-w-[70px] lg:max-w-[136px]';
      case CategoriesStyle.Rectangle:
        return 'max-h-[112px] lg:max-h-[169px] max-w-[90px] lg:max-w-[136px]';
      case CategoriesStyle.RectangleWithTextBackground:
        return 'max-h-[96px] lg:max-h-[136px] max-w-[96px] lg:max-w-[136px]';
      case CategoriesStyle.DoubleLineSmallCircle:
        return 'max-h-[80px] lg:max-h-[100px] max-w-[80px] lg:max-w-[100px]';
      case CategoriesStyle.SquareMultiLine:
        return 'max-h-[80px] lg:max-h-[136px] max-w-[80px] lg:max-w-[136px]';
      case CategoriesStyle.RectangleMultiLine:
        return 'max-h-[112px] lg:max-h-[169px] max-w-[90px] lg:max-w-[136px]';
      case CategoriesStyle.SpecialMultiline:
        if (index == 0) {
          if (hasLabel) {
            return 'max-h-[222px] max-w-[222px] lg:max-h-[336px] lg:max-w-[336px]';
          } else {
            return 'max-h-[168px] max-w-[168px] lg:max-h-[336px] lg:max-w-[336px]';
          }
        } else {
          if (hasLabel) {
            return 'max-h-[80px] max-w-[80px] lg:max-h-[128px] lg:max-w-[128px]';
          } else {
            return 'max-h-[80px] max-w-[80px] lg:max-h-[160px] lg:max-w-[160px]';
          }
        }
      case CategoriesStyle.SpecialMultilineTall:
        if (index == 0) {
          if (hasLabel) {
            return 'max-h-[286px] max-w-[230px] lg:max-h-[482px] lg:max-w-[385px]';
          } else {
            return 'max-h-[232px] max-w-[186px] lg:max-h-[482px] lg:max-w-[385px]';
          }
        } else {
          if (hasLabel) {
            return 'max-h-[112px] max-w-[90px] lg:max-h-[202px] lg:max-w-[162px]';
          } else {
            return 'max-h-[112px] max-w-[90px] lg:max-h-[233px] lg:max-w-[188px]';
          }
        }
      case CategoriesStyle.Tall:
        return 'max-h-[320px] lg:max-h-[371px] max-w-[196px] lg:max-w-[228px]';
      case CategoriesStyle.Wide: {
        return 'max-h-[88px] lg:max-h-[200px] max-w-[172px] lg:max-w-[389px]';
      }
      case CategoriesStyle.Style12: {
        return 'max-h-[70px]  lg:max-h-[96px] max-w-[100px] lg:max-w-[136px]';
      }
      case CategoriesStyle.Style13: {
        return 'max-h-[83px]  lg:max-h-[116.8px] max-w-[112px] lg:max-w-[158px]';
      }
      default:
        return null;
    }
  }
  const placeholderForInsufficentSlides = {
    title: '',
    label: '',
    link: '',
    link_type: '',
    logged_in_users_only: false,
    mobile_image: '',
    web_image: '',
    name: '',
    description: '',
  };
  let fixedListOfItems = data?.items;
  const isEven = (fixedListOfItems?.length ?? 0) % 2 === 0;
  // Empty items to make the last rows look good
  if (
    currentStyle === CategoriesStyle.SpecialMultiline ||
    currentStyle === CategoriesStyle.SpecialMultilineTall
  ) {
    fixedListOfItems = data?.items.concat(
      Array.from(
        { length: isEven ? 3 : 2 },
        () => placeholderForInsufficentSlides
      )
    );
  }

  function calculateNavButtonPosition() {
    switch (currentStyle) {
      case CategoriesStyle.Circle:
        return '!top-[35px] lg:!top-[50px]';
      case CategoriesStyle.Square:
        return '!top-[35px] lg:!top-[68px]';
      case CategoriesStyle.Rectangle:
        return '!top-[66px] lg:!top-[84.5px]';
      case CategoriesStyle.RectangleWithTextBackground:
        return '!top-[48px] lg:!top-[68px]';
      case CategoriesStyle.DoubleLineSmallCircle:
        return '!top-[88px] lg:!top-[112px]';
      case CategoriesStyle.SquareMultiLine:
        return '!top-[88px] lg:!top-[148px]';
      case CategoriesStyle.RectangleMultiLine:
        return '!top-[120px] lg:!top-[181px]';
      case CategoriesStyle.SpecialMultiline:
        if (slidesHasLabel) return '!top-[111px] lg:!top-[168px]';
        else return '!top-[84px] lg:!top-[168px]';
      case CategoriesStyle.SpecialMultilineTall:
        if (slidesHasLabel) return '!top-[143px] lg:!top-[241px]';
        else return '!top-[116px] lg:!top-[241px]';
      case CategoriesStyle.Tall:
        return '!top-[160px] lg:!top-[185.5px]';
      case CategoriesStyle.Wide:
        if (slidesHasLabel) return '!top-[64px] lg:!top-[126.5px]';
        else return '!top-[44px] lg:!top-[100px]';
      case CategoriesStyle.Style12:
        return '!top-[35px] lg:!top-[48px]';
      case CategoriesStyle.Style13:
        if (slidesHasLabel) return '!top-[78.5px] lg:!top-[95.35px]';
        else return '!top-[41.5px] lg:!top-[58.4px]';
      default:
        return '!top-1/2';
    }
  }
  return (
    <HydrationWrapper>
      <HomeBannersLayout
        title={data?.section_title || ''}
        titleCentered={data && Boolean(data?.items?.length <= 4)}
        titleColor={data?.color_theme?.title_color}
        titleClass={data?.title_class}
        showAll={data?.label}
        showAllLink={data?.link}
        showAllLinkColor={data?.color_theme?.cta_color}
        backgroundColor={data?.color_theme?.color_theme?.background_color}
        backgroundImage={data?.background_image}
      >
        {data && Array.isArray(data?.items) && data.items.length <= 4 ? (
          <div className='flex justify-between'>
            {data?.items
              ?.filter(
                (categoryItem) =>
                  !categoryItem.logged_in_users_only || isLoggedIn
              )
              ?.map((categoryItem: ItemCategoryType, index: number) => (
                <div
                  key={index}
                  className='flex h-full w-full flex-col text-xs'
                >
                  <ContentfulLink
                    item={categoryItem}
                    language={language}
                    region={region}
                  >
                    <div
                      className={twMerge(
                        clsx(
                          'relative flex w-full flex-col items-center',
                          'rounded-[10px]',
                          style?.hasShadow && 'shadow-lg'
                        )
                      )}
                      style={{
                        backgroundColor:
                          data?.color_theme?.description_background_color,
                        aspectRatio: style.imageAspectRatio,
                      }}
                    >
                      <div
                        className={twMerge(
                          clsx(
                            'relative h-full',
                            style?.imageAspectRatio &&
                              calculateImageSize(
                                index,
                                Boolean(categoryItem.label)
                              )
                          )
                        )}
                        style={{
                          aspectRatio: style.imageAspectRatio,
                        }}
                      >
                        <Media
                          srcset={{
                            mobileImage: categoryItem?.mobile_image,
                            desktopImage: categoryItem?.web_image,
                            desktopImageWidth: categoryItem?.web_image_width,
                            desktopImageHeight: categoryItem?.web_image_height,
                            mobileImageWidth: categoryItem?.mobile_image_width,
                            mobileImageHeight:
                              categoryItem?.mobile_image_height,
                          }}
                          alt={categoryItem.name || 'NAHDI'}
                          className={twMerge(
                            clsx(
                              'rounded-[10px]',
                              style?.imageIsCircular &&
                                'rounded-full border-2 border-white',
                              style?.titleHasBackground &&
                                categoryItem.label &&
                                'rounded-b-none',
                              'swiper-lazy h-full w-full object-cover'
                            )
                          )}
                          fill
                          decoding='async'
                          fetchPriority={
                            index < calculateSliderLength() ? 'high' : 'low'
                          }
                        />
                      </div>
                      {categoryItem.label && (
                        <h6
                          className={clsx(calculateLabel(categoryItem, index))}
                          style={{
                            color:
                              data.color_theme?.color_theme?.label_text_color,
                            backgroundColor: data?.color_theme?.border_color,
                          }}
                        >
                          {categoryItem.label}
                          {style?.includeDescription &&
                            categoryItem.description && (
                              <span
                                className='mb-3 mt-2 block w-full flex-1 whitespace-nowrap text-center text-custom-xs font-normal text-gray-dark'
                                style={{
                                  color: data.color_theme?.description_color,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: categoryItem.description,
                                }}
                              />
                            )}
                        </h6>
                      )}
                    </div>
                  </ContentfulLink>
                </div>
              ))}
          </div>
        ) : (
          <div className='relative'>
            <BaseSwiper
              swiperProps={swiperProps}
              className={clsx(
                data?.section_title ? '!my-0 !pt-0' : '',
                customClassName
              )}
            >
              {fixedListOfItems
                ?.filter(
                  (categoryItem) =>
                    !categoryItem.logged_in_users_only || isLoggedIn
                )
                ?.map((categoryItem: ItemCategoryType, index: number) => (
                  <div
                    key={index}
                    className={clsx(
                      'flex h-full w-full flex-col text-xs',
                      index !== 0 &&
                        currentStyle === CategoriesStyle.SpecialMultiline &&
                        index % 2 !== 0
                        ? 'mb-2'
                        : ''
                    )}
                  >
                    <ContentfulLink
                      item={categoryItem}
                      className='h-full w-full'
                      language={language}
                      region={region}
                    >
                      <div
                        className={twMerge(
                          clsx(
                            'relative flex flex-col items-center',
                            'h-full w-full rounded-[10px]',
                            style?.hasShadow && 'shadow-lg'
                          )
                        )}
                        style={{
                          backgroundColor:
                            data?.color_theme?.description_background_color,
                          ...(currentStyle ===
                            CategoriesStyle.SpecialMultiline && index === 0
                            ? { aspectRatio: style.imageAspectRatio }
                            : {}),
                        }}
                      >
                        <div
                          className={clsx(
                            'relative h-full w-full',
                            calculateImageSize(
                              index,
                              Boolean(categoryItem.label)
                            )
                          )}
                          style={{ aspectRatio: style.imageAspectRatio }}
                        >
                          <Media
                            srcset={{
                              mobileImage: categoryItem?.mobile_image,
                              desktopImage: categoryItem?.web_image,
                              desktopImageWidth: categoryItem?.web_image_width,
                              desktopImageHeight:
                                categoryItem?.web_image_height,
                              mobileImageWidth:
                                categoryItem?.mobile_image_width,
                              mobileImageHeight:
                                categoryItem?.mobile_image_height,
                            }}
                            alt={categoryItem.name || 'NAHDI'}
                            className={twMerge(
                              clsx(
                                'rounded-[10px]',
                                style?.imageIsCircular &&
                                  'rounded-full border-2 border-white',
                                style?.titleHasBackground &&
                                  categoryItem.label &&
                                  'rounded-b-none',
                                'swiper-lazy h-full w-full object-cover'
                              )
                            )}
                            fill
                            decoding='async'
                            fetchPriority={
                              index < calculateSliderLength() ? 'high' : 'low'
                            }
                          />
                          {categoryItem?.mobile_image ||
                          categoryItem?.web_image ? (
                            <div className='swiper-lazy-preloader'></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {categoryItem.label && (
                          <h6
                            className={clsx(
                              calculateLabel(categoryItem, index)
                            )}
                            style={{
                              color:
                                data?.color_theme?.color_theme
                                  ?.label_text_color,
                              backgroundColor: data?.color_theme?.border_color,
                            }}
                          >
                            {categoryItem.label}
                            {style?.includeDescription &&
                              categoryItem.description && (
                                <span
                                  className='mb-3 mt-2 block w-full flex-1 text-center text-custom-xs font-normal text-gray-dark'
                                  style={{
                                    color: data?.color_theme?.description_color,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: categoryItem?.description,
                                  }}
                                />
                              )}
                          </h6>
                        )}
                      </div>
                    </ContentfulLink>
                  </div>
                ))}
            </BaseSwiper>
            {data?.items?.length &&
              data?.items?.length > calculateSliderLength() && (
                <div className='hidden lg:block'>
                  <button
                    type='button'
                    className={clsx(
                      `custom-${componentIndex}-category-left-button swiper-button-prev custom-swiper-button-prev ${calculateNavButtonPosition()}`
                    )}
                  ></button>
                  <button
                    type='button'
                    className={clsx(
                      `custom-${componentIndex}-category-right-button swiper-button-next custom-swiper-button-next ${calculateNavButtonPosition()}`
                    )}
                  ></button>
                </div>
              )}
          </div>
        )}
      </HomeBannersLayout>
    </HydrationWrapper>
  );
}
